/* https://stackoverflow.com/questions/23083462/how-to-get-an-iframe-to-be-responsive-in-ios-safari/23083463#23083463 
   By default, mobile Safari overrides width: 100% for iframes – theoretically due to lack of scrolling? – this fixes that
*/
.responsive-iframe-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
}

.responsive-iframe-container iframe {
  width: 1px;
  min-width: 100%;
  height: 1px;
  min-height: 100%;
  border: none;
}

.iframe-poster {
  display: flex;
  width: 100%;
}

.iframe-poster__poster {
  width: 100%;
  align-self: center;
}

.iframe-poster__play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 60px;
  width: 60px;
  transition: all 0.4s;
  cursor: pointer;
}

.iframe-poster:hover .iframe-poster__play {
  opacity: 0.85;
  transition: all 0s;
}

.responsive-iframe-container > p {
  justify-content: center;
  text-align: center;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.interactives-toolbar-holder {
  position: relative;
  width: 100%;
  height: 36px;
}

.toolbar-shadow {
  position: absolute;
  bottom: -2px !important;
  left: 0;
  width: 100%;
  z-index: 9;
  height: 36px;
  background-color: rgba(0, 0, 0, 0.6);
}
.interactives-toolbar-holder .toolbar-shadow {
  bottom: 0 !important;
}

.fullscreen-btn {
  position: absolute;
  bottom: 0px;
  height: 34px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  right: 0;
  font-size: 0.95em;
  padding-right: 0.6em;
  z-index: 9;
  cursor: pointer;
}
.interactives-toolbar-holder .fullscreen-btn {
  bottom: 1px;
}
.fullscreen-btn img {
  width: 26px;
  height: 26px;
}
.fullscreen-btn:hover img {
  opacity: 0.9;
}
