#actors-page {
  display: flex;
  flex-direction: column;
}

.actors-page-content {
  display: flex;
  height: 100%;
}

.actors-page-content__list {
  flex: 1.4;
}

.actors-page-content__detail {
  flex: 2.5;
  background-color: black;
  margin-left: -10px;
  padding: 10px;
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: 1.5em;
}

.card-grid {
  display: grid;
  padding: 1em;
  grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
  grid-auto-rows: 12rem;
  grid-gap: 1em;
  grid-auto-flow: dense;
  overflow-y: scroll;
}

.cast-member-card {
  background-color: rgba(27, 27, 27, 0.9);
  padding: 1em;
  border-radius: 0.2em;
  box-sizing: border-box;
  display: grid;
  grid-template-areas: "header";
  grid-template-rows: 100%;
  grid-template-columns: 100%;
}

.detail-card {
  grid-row: span 2;
  grid-column: span 2;
  overflow: hidden;
}

.detail-card .cast-member-card {
  grid-template-areas:
    "header"
    "body";
  grid-template-rows: minmax(1px, 2fr) minmax(1px, 3fr);
}

.detail-card .card__header {
  width: 75%;
  margin: auto;
  grid-template-areas: "headerImage headerInfo";
  grid-template-columns: minmax(1px, 1fr) minmax(1px, 2fr);
  grid-template-rows: minmax(1px, 1fr);
}

.detail-card .card-info__title {
  font-size: 1.2em;
}

.simple-detail-card {
  grid-template-areas:
    "header"
    "body";
  grid-template-rows: minmax(5em, 1fr) minmax(1px, 2fr);
}

.simple-detail-card .card__header {
  grid-template-areas: "headerImage";
  grid-template-rows: minmax(1px, 1fr);
}

.simple-detail-card .card__body {
  font-size: 1rem;
}
