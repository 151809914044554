.category-page {
  height: 100%;
  display: flex;
  flex-direction: column;
}
#extras-page-scroll .extras-grid {
  scrollbar-color: #e7b74f black;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(199, 199, 36);
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1),
    inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}
/* Lay out side-by-side in wider aspect ratios */
@media (min-aspect-ratio: 5/4) {
  .category-page .page-body {
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
  }

  .category-page__content {
    flex: 3;
  }

  .category-page__list {
    flex: 2;
  }
}

.category-page__content {
  width: 100%;
}

.category-page__main {
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  background-color: black;
  position: relative;
  z-index: 1;
}

/* When in fullscreen, let abs positioning set the size */
.category-page__main--fs {
  padding: 0;
}

.category-page__main__inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.category-page__detail {
  padding: 0.75em;
  height: 3em;
  text-align: center;
}

.category-page__detail__name {
  padding-top: 0.25em;
  font-size: 1.2em;
}

.category-page__list {
  display: grid;
  grid-auto-rows: min-content;
  height: 100%;
  width: 100%;
  padding: 12px;
  -webkit-overflow-scrolling: touch; /* Bouncy scroll on iOS */
}

@media (min-width: 70em) {
  .category-page__list {
    grid-template-columns: repeat(auto-fit, minmax(22em, 1fr));
  }
}

.vjs-time-control span {
  user-select: none;
}
.video-react .video-react-big-play-button {
  position: center !important;
}
