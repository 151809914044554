.back-button {
  flex: 1;
  height: 1.3em;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.back-button img {
  height: 100%;
  margin-right: 0.4em;
}
