.Extras {
  position: absolute;
  top: 12px;
  right: 5px;
  z-index: 111;
}
.page-header {
  width: 100vw;
  height: 70px;
  display: grid;
  grid-template-columns: minmax(1px, 1fr) minmax(1px, 2fr) minmax(1px, 1fr);
  grid-template-areas: "back logo page-name";
  grid-gap: 0.3em;
  align-items: center;
  padding: 15px 20px 0px 20px;
  box-sizing: border-box;
  position: relative;
  flex-shrink: 0;
}

.page-header__title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.3em;
  text-transform: uppercase;
  grid-area: logo;
}

.page-header__logo {
  width: 100%;
  height: 100%;
  flex: 2;
  object-fit: contain;
  margin: 0px auto;
  margin-top: -0.4em;
  grid-area: logo;
}

.page-header__name {
  flex: 1;
  text-align: right;
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  grid-area: page-name;
  position: absolute;
  top: 10px;
  right: 2px;
}
