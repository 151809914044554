.no-extras__container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 350px;
  margin: 5% auto;
}

.no-extras__box {
  border-radius: 10px;
  padding: 4rem;
  background: rgb(253, 230, 98);
  background: linear-gradient(
    90deg,
    rgba(236, 237, 113, 1) 0%,
    rgba(219, 195, 57, 1) 47%,
    rgba(209, 163, 26, 1) 100%
  );
}

.no-extras__text {
  color: rgba(34, 34, 34);
  font-weight: 900;
  font-size: 2rem;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .no-extras__box {
    padding: 2rem;
  }
  .no-extras__text {
    font-size: 1.4rem;
  }
}
