.scroll-to-top {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 999;
    cursor: pointer;
}

.scroll-to-top img {
    width: 25px;
}
