.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 2em;
  box-sizing: border-box;
}

.error-page > img {
  width: 40%;
  min-width: 240px;
  margin-top: 1em;
}
